import { PercentPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { APOLLO_NAMED_OPTIONS, NamedOptions } from 'apollo-angular';

import { DeviceDiscoveryApiService, InventoryDevicesApiService } from '../api';
import { CloudDeviceApiService } from '../api/cloud-device-api.service';

import { SysApiCloudDeviceApiService } from './sys-api-cloud-device-api.service';
import { SysApiConnectionService } from './sys-api-connection.service';
import { SysApiDeviceDiscoveryApiService } from './sys-api-device-discovery-api.service';
import { SysApiInventoryDevicesApiService } from './sys-api-inventory-devices-api.service';

@NgModule({
	providers: [
		{
			provide: CloudDeviceApiService,
			useClass: SysApiCloudDeviceApiService
		},
		{
			provide: DeviceDiscoveryApiService,
			useClass: SysApiDeviceDiscoveryApiService
		},
		{
			provide: InventoryDevicesApiService,
			useClass: SysApiInventoryDevicesApiService
		},
		SysApiConnectionService,
		{
			provide: APOLLO_NAMED_OPTIONS,
			useFactory(sysApiConnectionService: SysApiConnectionService): NamedOptions {
				const options: NamedOptions = {
					sysApi: sysApiConnectionService.createApolloClientOptions()
				};
				return options;
			},
			deps: [SysApiConnectionService]
		},
		PercentPipe
	]
})
export class SysApiDevicesDataAccessModule {}
