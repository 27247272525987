import { DateTime } from 'ts-luxon';

import { DeviceLicenseState } from '@shure/shared/angular/data-access/system-api/models';

import { DeviceLicenseInfoFragment, LicenseState } from '../graphql/generated/cloud-sys-api';
import { LicenseInfo } from '../models/license-info';

export function mapLicenseInfoFromSysApi(licenseInfos?: DeviceLicenseInfoFragment[]): LicenseInfo[] | undefined {
	return licenseInfos?.map((licenseInfo) => ({
		count: licenseInfo?.count ? licenseInfo?.count : 0,
		expirationDate: licenseInfo?.expirationDate ? DateTime.fromISO(licenseInfo.expirationDate) : undefined,
		featureName: licenseInfo?.featureName ?? undefined,
		state: mapDeviceLicenseStateFromSysApi(licenseInfo?.state)
	}));
}

function mapDeviceLicenseStateFromSysApi(
	licenseStateSysApi: Omit<LicenseState, 'CLOCKWINDBACK'> | undefined
): DeviceLicenseState {
	switch (licenseStateSysApi) {
		case undefined:
		case LicenseState.Expired:
			return DeviceLicenseState.Unlicensed;

		case LicenseState.Valid:
			return DeviceLicenseState.ActivationConfirmed;

		case LicenseState.PendingExpiration:
			return DeviceLicenseState.Expired;

		case LicenseState.PendingActivationNetwork:
			return DeviceLicenseState.CommunicationIssue;

		case LicenseState.PendingActivationInsufficient:
			return DeviceLicenseState.Insufficient;

		case LicenseState.PendingActivationOffline:
			return DeviceLicenseState.ActivationPending;

		case LicenseState.PendingDeactivation:
			return DeviceLicenseState.DeactivationPending;

		case LicenseState.FailedActivationKeyError:
			return DeviceLicenseState.InvalidId;

		case LicenseState.Clockwindback:
			return DeviceLicenseState.ClockSetting;

		case LicenseState.InternalError:
		case LicenseState.Tampered:
			return DeviceLicenseState.UnknownIssue;

		default:
			throw 'Unhandled LicenseState: ' + licenseStateSysApi;
	}
}
