import { DeviceStatus, InventoryDeviceFragment } from '../graphql/generated/cloud-sys-api';
import { InventoryDevice } from '../models/inventory-device';

import { mapLicenseInfoFromSysApi } from './map-license-info';
import { mapDeviceModelFromSysApi, mapDeviceStateFromSysApi, mapFeatureValueFromSysApi } from './utils';

export function mapInventoryDeviceFromSysApi(device: InventoryDeviceFragment): InventoryDevice {
	const name = mapFeatureValueFromSysApi(device.features.name?.name);

	const controlNetwork = {
		ipAddress: device.features.controlNetwork
			? mapFeatureValueFromSysApi(device.features.controlNetwork.interface.ipAddress)
			: mapFeatureValueFromSysApi(device.protocol.dmp?.ipAddress),
		macAddress: mapFeatureValueFromSysApi(device.features.controlNetwork?.interface.macAddress),
		isMissing: !device.description.features.controlNetwork.supported
	};

	const firmware = {
		version: mapFeatureValueFromSysApi(device.features.firmware?.version),
		valid: device.features.firmware?.valid || true,
		isUpToDate: true,
		isMissing: !device.description.features.firmware.supported
	};

	const serialNumber = {
		serialNumber: mapFeatureValueFromSysApi(device.features.serialNumber?.serialNumber),
		isMissing: !device.description.features.serialNumber.supported
	};

	const audioChannelCount = device.features.audioChannelCount;

	const license = device.description.features.license.supported
		? {
				info: mapLicenseInfoFromSysApi(device.features.license?.info ?? []),
				isMissing: device.features.license === null
		  }
		: device.description.features.licenseV2.supported
		  ? {
					info: mapLicenseInfoFromSysApi(device.features.licenseV2?.info ?? []),
					isMissing: device.features.licenseV2 === null
		    }
		  : undefined;

	const identify = {
		identifying: device.features.identify?.identifying || false,
		isMissing: !device.description.features.identify.supported
	};
	const isDisabled = device.status === DeviceStatus.Online;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const unaugmentedDevice: any = {
		id: device.id,
		name,
		model: mapDeviceModelFromSysApi(device.interface.model ?? ''),
		virtual: device.virtual,
		deviceState: mapDeviceStateFromSysApi(device.status),
		hardwareId: device.hardwareId,
		features: {
			controlNetwork,
			firmware,
			serialNumber,
			audioChannelCount,
			license,
			identify,
			isDisabled
		}
	};

	return {
		...unaugmentedDevice
	};
}
