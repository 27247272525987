import { HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { ApolloModule } from 'apollo-angular';

import { ApolloCacheManager } from './apollo-cache-manager.service';
import { APOLLO_TYPE_POLICY_PROVIDERS } from './apollo-type-policy.provider';
import { SysApiTypePolicyProvider } from './sys-api';

@NgModule({
	imports: [ApolloModule, HttpClientModule]
})
export class ApolloSysApiCloudModule {
	public static forRoot(): ModuleWithProviders<ApolloSysApiCloudModule> {
		return {
			ngModule: ApolloSysApiCloudModule,
			providers: [
				ApolloCacheManager,
				{
					provide: APOLLO_TYPE_POLICY_PROVIDERS,
					multi: true,
					useClass: SysApiTypePolicyProvider
				}
			]
		};
	}
}
