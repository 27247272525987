import { Injectable, Inject, signal, effect } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import {
	map,
	Observable,
	tap,
	catchError,
	throwError,
	Subscription,
	takeUntil,
	Subject,
	BehaviorSubject,
	shareReplay,
	debounceTime,
	take
} from 'rxjs';

import { SubscriptionManager, SubscriptionManagerConfigCreate } from '@shure/cloud/shared/apollo';
import { UpdateResponse } from '@shure/cloud/shared/models/http';
import { OktaInterfaceService, monitorLoginState } from '@shure/cloud/shared/okta/data-access';
import { APP_ENVIRONMENT, AppEnvironment } from '@shure/cloud/shared/utils/config';
import { DeviceModel } from '@shure/shared/angular/data-access/system-api/models';
import { ILogger } from '@shure/shared/angular/utils/logging';

import { CloudDeviceApiService } from '../api/cloud-device-api.service';
import { DeviceDiscoveryApiService } from '../api/device-discovery-api.service';
import { InventoryDevicesApiService } from '../api/inventory-devices-api.service';

import {
	NodeChangeType,
	InventoryDeviceByIdQueryGQL,
	InventoryDeviceSubscriptionGQL
} from './graphql/generated/cloud-sys-api';
import { mapInventoryDeviceFromSysApi } from './mappers/map-inventory-device';
import { InventoryDevice } from './models/inventory-device';

@Injectable({ providedIn: 'root' })
export class SysApiInventoryDevicesApiService implements InventoryDevicesApiService {
	public deviceInventoryQueryInProgress$ = new BehaviorSubject<boolean>(false);
	public deviceInventory$ = new BehaviorSubject<InventoryDevice[]>([]);

	// Public signal for the device inventory loading indicator. Set to true when initial loading begins
	// and false when we think we're initially loaded. Once set to false when initial loading is done
	// it will not be set to true again unless the user were to logout/in or refresh the page.
	public deviceInventoryLoading = signal(false);

	// This signal is used like a popcorn time to help control the deviceInventoryLoading signal.
	// If we haven't loaded all the devices in the inventory, but changes stop occuring, this is set to false
	// Similar to a popcorn timer... when the kernels stop popping, or in our case, the inventory device count
	// stops changing, the timer goes off.
	private inventoryCountChangedBeforeTimeout = signal(false);

	// This signal is tied to the number of devices in the inventory. It is compared to the number
	// of discoverd devices signal exposed by the discovery service to know how many devices we should expect
	// in the inventory.
	private deviceInventoryCount = toSignal(this.getInventoryDevicesCount$());

	protected readonly logger: ILogger;
	private destroy$ = new Subject<void>();

	private readonly devicesSubscriptionManager = new SubscriptionManager({
		subscriptionType: 'inventory-devices',
		create: (config): Subscription => this.createDeviceSubscription(config),
		retryWaitMs: 5000,
		maxRetryAttempts: 3
	});

	constructor(
		logger: ILogger,
		private readonly inventoryDeviceSubscriptionGQL: InventoryDeviceSubscriptionGQL,
		private readonly inventoryDeviceByIdQueryGQL: InventoryDeviceByIdQueryGQL,
		private readonly deviceDiscoveryService: DeviceDiscoveryApiService,
		private readonly oktaService: OktaInterfaceService,
		private readonly cloudDeviceService: CloudDeviceApiService,
		@Inject(APP_ENVIRONMENT) private readonly appEnv: AppEnvironment
	) {
		this.logger = logger.createScopedLogger('DaiInventoryDevicesService');
		monitorLoginState(this.oktaService, {
			onLogIn: this.initService.bind(this),
			onLogOut: this.suspendService.bind(this)
		});

		effect(
			() => {
				const discoveryInProgress = this.deviceDiscoveryService.deviceDiscoveryInProgress();
				const numDiscoveredDevices = this.deviceDiscoveryService.numDiscoveredDevices();
				const numInventoryDevices = this.deviceInventoryCount();
				const inventoryCountChangedBeforeTimeout = this.inventoryCountChangedBeforeTimeout();

				let tempIsLoading = discoveryInProgress || numDiscoveredDevices !== numInventoryDevices;
				if (tempIsLoading === false) {
					this.inventoryCountChangedBeforeTimeout.set(false);
				} else if (inventoryCountChangedBeforeTimeout === false) {
					tempIsLoading = false;
				}

				this.logger.debug(
					'deviceInventoryLoading',
					'signal',
					`DiscoveryInProgress:    ${discoveryInProgress}\n` +
						`Num Discovered Devices: ${numDiscoveredDevices}\n` +
						`Num Inventory Devices:  ${numInventoryDevices}\n` +
						`Inv Count Δ B4 Timeout: ${inventoryCountChangedBeforeTimeout}\n` +
						`Is Loading (result):    ${tempIsLoading}`
				);
				this.deviceInventoryLoading.set(tempIsLoading);
			},
			{ allowSignalWrites: true }
		);
	}

	public getInventoryDevicesCount$(): Observable<number> {
		return this.getInventoryDevices$().pipe(map((devices) => devices.length));
	}

	public getInventoryDevices$(deviceModel?: DeviceModel[]): Observable<InventoryDevice[]> {
		return this.deviceDiscoveryService
			.getDiscoveredDevicesByQuery$<InventoryDevice>(
				(id) => this.getInventoryDevice$(id).pipe(takeUntil(this.destroy$)), // query fct
				(_device) => true, // filter function (don't remove any)
				deviceModel
			)
			.pipe(
				shareReplay({ refCount: true, bufferSize: 1 }),
				tap((devices) => this.deviceInventory$.next(devices))
			);
	}

	public setIdentify(deviceId: string, identify: boolean): Observable<UpdateResponse<void, string>> {
		this.logger.trace('setIdentify()', 'Setting identify', { deviceId, identify });
		return this.cloudDeviceService.setIdentify(deviceId, identify);
	}

	public refreshLicense(id: string, transactionId: string): Observable<unknown> {
		this.logger.trace('refreshLicense()', 'refresh license', { id, transactionId });
		return this.cloudDeviceService.refreshLicense(id, transactionId);
	}

	public getInventoryDevice$(deviceId: string): Observable<InventoryDevice> {
		return this.inventoryDeviceByIdQueryGQL
			.watch(
				{
					nodeId: deviceId
				},
				{
					errorPolicy: 'ignore',
					fetchPolicy: 'cache-first'
				}
			)
			.valueChanges.pipe(
				tap((query) => {
					this.logger.debug('inventoryDeviceByIdQueryGQL', 'Received update', JSON.stringify({ query }));
				}),
				map((query) => {
					const device = query.data.node;
					if (device && 'isDevice' in device) {
						return mapInventoryDeviceFromSysApi(device);
					}
					throw query.error;
				}),
				tap((device) => {
					// when query response received, set up the per-device subscription.
					this.devicesSubscriptionManager.register([device.id]);
				}),
				catchError((error: Error) => {
					this.logger.error(
						'getInventoryDevice$()',
						'Failed to query device',
						JSON.stringify({ deviceId, error })
					);
					return throwError(() => error);
				})
			);
	}

	private initService(): void {
		this.logger.information('initService', 'user logged in, initializating service');
		this.destroy$ = new Subject();
		this.inventoryCountChangedBeforeTimeout.set(true);
		this.getInventoryDevicesCount$()
			.pipe(
				debounceTime(10000),
				take(1), // we don't need this subscription running past the first debounce expiration.
				takeUntil(this.destroy$)
			)
			.subscribe(() => {
				this.inventoryCountChangedBeforeTimeout.set(false);
			});
		this.deviceDiscoveryService
			.deviceRemoved$()
			.pipe(takeUntil(this.destroy$))
			.subscribe({
				next: (removedDevice) => {
					this.devicesSubscriptionManager.deregister(removedDevice);
				}
			});
	}

	private suspendService(): void {
		this.logger.information('suspendService', 'user logged out, suspending service');
		this.destroy$.next();
		this.destroy$.complete();
		this.devicesSubscriptionManager.deregisterAll();
	}

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	private createDeviceSubscription({ id, retryCallback }: SubscriptionManagerConfigCreate): Subscription {
		return this.inventoryDeviceSubscriptionGQL
			.subscribe(
				{
					id,
					types: [
						NodeChangeType.DeviceName,
						NodeChangeType.DeviceIdentify,
						NodeChangeType.DeviceAudioChannelCount,
						NodeChangeType.DeviceLicense,
						NodeChangeType.DeviceLicenseV2
					]
				},
				{
					errorPolicy: 'ignore',
					fetchPolicy: 'network-only' //  we always want subscription data from the server
				}
			)
			.pipe(takeUntil(this.destroy$))
			.subscribe({
				next: (change) => {
					this.logger.debug(
						'inventoryDeviceSubscriptionGQL',
						'Received update',
						JSON.stringify({
							id,
							change
						})
					);
				},
				complete: () => {
					this.logger.warning('inventoryDeviceSubscriptionGQL', 'subscription completed', {
						id
					});
				},
				error: (error) => {
					this.logger.error(
						'inventoryDeviceSubscriptionGQL',
						'Encountered error',
						JSON.stringify({ id, error })
					);
					retryCallback();
				}
			});
	}
}
