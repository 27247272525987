import { Observable } from 'rxjs';

// TODO: LBF 7/12/2023
export abstract class SystemService {
	/**
	 * Currently active network id.
	 */
	// public abstract readonly activeNetworkId$: Observable<string>;

	/**
	 * Currently active network id.
	 */
	// public abstract readonly apiState$: Observable<ApiState>;

	/**
	 * Whether the system is ready or not.
	 */
	public abstract isSystemReady(): Observable<boolean>;

	/**
	 * Emits `true` for systemApiReady if SystemAPI started, and `true` for fileExists if app is started with a file to be opened.
	 */
	// public abstract waitForSystemReady(): Observable<{ sysApiReady: boolean; fileExists: boolean }>;

	/**
	 * Update network id used for device discovery.
	 */
	// public abstract updateNetworkId(networkId: string): Observable<UpdateRequestResponse<string>>;

	/**
	 * Get connect information for system api.
	 */
	public abstract getSysApiConnectInfo(): Observable<{ url: string; apiKey: string }>;
}
