import { Injectable } from '@angular/core';
import { Observable, map, catchError, of } from 'rxjs';

import { UpdateRequestResponse, UpdateState } from '@shure/cloud/shared/models/http';
import { ILogger } from '@shure/shared/angular/utils/logging';

import { CloudDeviceApiService } from '../api/cloud-device-api.service';

import {
	DeviceStartIdentifyMutationGQL,
	DeviceStopIdentifyMutationGQL,
	RefreshDeviceLicenseInput,
	RefreshLicenseGQL
} from './graphql/generated/cloud-sys-api';

@Injectable({ providedIn: 'root' })
export class SysApiCloudDeviceApiService extends CloudDeviceApiService {
	private readonly logger: ILogger;

	constructor(
		logger: ILogger,
		private readonly deviceStopIdentifyMutationGQL: DeviceStopIdentifyMutationGQL,
		private readonly deviceStartIdentifyMutationGQL: DeviceStartIdentifyMutationGQL,
		private readonly refreshLicenseGQL: RefreshLicenseGQL
	) {
		super();

		this.logger = logger.createScopedLogger('DaiCloudDeviceService');
	}

	/**
	 * Toggle identify for a device.
	 * @param deviceId
	 * @param identify
	 * @returns
	 */
	public setIdentify(deviceId: string, identify: boolean): Observable<UpdateRequestResponse<string>> {
		this.logger.trace('toggleIdentify', 'Toggling identify', { deviceId, identify });
		const mutation$: Observable<unknown> = identify
			? this.deviceStartIdentifyMutationGQL.mutate({ startIdentifyId: deviceId })
			: this.deviceStopIdentifyMutationGQL.mutate({ stopIdentifyId: deviceId });

		return mutation$.pipe(
			map(() => {
				this.logger.trace('toggleIdentify', 'Done', { deviceId });
				return { state: UpdateState.Done };
			}),
			catchError((error: Error) => {
				this.logger.error('toggleIdentify', 'Error', { error });
				return of({ state: UpdateState.Error, error: error.message });
			})
		);
	}

	/**
	 * Refresh license for a device.
	 * @param id
	 * @param transactionId
	 * @returns
	 */
	public refreshLicense(id: string, transactionId: string): Observable<unknown> {
		this.logger.trace('refreshLicense', 'refresh license for device', { id, transactionId });
		const input: RefreshDeviceLicenseInput = { id: id, transactionId: transactionId };
		const mutation$: Observable<unknown> = this.refreshLicenseGQL.mutate({ input: input });

		return mutation$.pipe(
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			map((state: any) => {
				this.logger.trace('refreshLicense', 'Done', { id, transactionId });
				return { state: state?.data?.refreshDeviceLicense };
			}),
			catchError((error: Error) => {
				this.logger.error('refreshLicense', 'Error', { error });
				return of({ state: UpdateState.Error, error: error.message });
			})
		);
	}
}
