import { Signal } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { UpdateResponse } from '@shure/cloud/shared/models/http';
import { DeviceModel } from '@shure/shared/angular/data-access/system-api/models';

import { InventoryDevice } from '../api-instance-sys-api/models/inventory-device';

export abstract class InventoryDevicesApiService {
	public abstract deviceInventoryQueryInProgress$: BehaviorSubject<boolean>;
	public abstract deviceInventory$: BehaviorSubject<InventoryDevice[]>;

	/**
	 * Loading state for inventory devices.
	 */
	public abstract deviceInventoryLoading: Signal<boolean>;
	/**
	 * Get # of inventory devices.
	 */
	public abstract getInventoryDevicesCount$(): Observable<number>;

	/**
	 * Get all inventory devices.
	 */
	public abstract getInventoryDevices$(deviceModel?: DeviceModel[]): Observable<InventoryDevice[]>;

	/**
	 * Get inventory device by ID.
	 */
	public abstract getInventoryDevice$(deviceId: string): Observable<InventoryDevice>;

	/**
	 * Set identify for a device.
	 */
	public abstract setIdentify(deviceId: string, identify: boolean): Observable<UpdateResponse<void, string>>;

	/**
	 * Set refresh license.
	 */
	public abstract refreshLicense(id: string, transactionId: string): Observable<unknown>;
}
