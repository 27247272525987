import { Injectable } from '@angular/core';

import { DeviceModel } from '@shure/shared/angular/data-access/system-api/models';

import { DeviceBehaviorPluginsService } from '../../device-behavior.service';

import { DeviceNameBehaviorPlugin } from './models';

const DEFAULT_DEVICE_NAME_MAX_LENGTH = 31;

/**
 * Provides naming behavior for a given device.
 */
@Injectable({ providedIn: 'root' })
export class DeviceNameBehaviorPluginService {
	constructor(private readonly plugins: DeviceBehaviorPluginsService) {}

	/**
	 * Get maximum character length for device name.
	 */
	public getDeviceNameMaxLength(model: DeviceModel): number {
		return (
			this.plugins
				.get<DeviceNameBehaviorPlugin>(model, ['getDeviceNameMaxLength'])
				?.getDeviceNameMaxLength(model) ?? DEFAULT_DEVICE_NAME_MAX_LENGTH
		);
	}
}
