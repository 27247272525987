import { Duration } from 'ts-luxon';

import { DeviceModel, DeviceState } from '@shure/shared/angular/data-access/system-api/models';

import { DeviceStatus } from '../graphql/generated/cloud-sys-api';

// wangth: The usage of this value should be removed, and replaced with test for isMissing attribute,
// which should go all the way to UI.
export const MISSING_VALUE = '..';

export function mapFeatureValueFromSysApi(value?: string | null): string {
	return value ?? MISSING_VALUE;
}

export function mapDeviceModelFromSysApi(model: string): DeviceModel {
	switch (model) {
		case 'MXA920-S':
			return DeviceModel.MXA920S;
		case 'MXA920-R':
			return DeviceModel.MXA920R;
		case 'P300':
			return DeviceModel.P300;
		case 'MXWAPXD2':
			return DeviceModel.MXWAPXD2;
		case 'MXA710-4FT':
			return DeviceModel.MXA7104FT;
		case 'MXA710-2FT':
			return DeviceModel.MXA7102FT;
		case 'ANIUSB-MATRIX':
			return DeviceModel.ANIUSB;
		case 'MXA901-R':
			return DeviceModel.MXA901R;
		case 'MXA901-S':
			return DeviceModel.MXA901S;
		case 'MXA902-S':
			return DeviceModel.MXA902S;
		case 'IMX-Room':
			return DeviceModel.IMXROOM;
		case 'MXN5-C':
			return DeviceModel.MXN5C;
		case 'ANX4':
			return DeviceModel.MCR;
		case 'AD600':
			return DeviceModel.AD600;
		case 'ADTD':
			return DeviceModel.ADTD;
		case 'ADTQ':
			return DeviceModel.ADTQ;
		default:
			return DeviceModel.UNKNOWN;
	}
}

export function mapDeviceStateFromSysApi(deviceStatus: DeviceStatus): DeviceState {
	switch (deviceStatus) {
		case DeviceStatus.Discovered:
			return DeviceState.Discovered;
		case DeviceStatus.Error:
			return DeviceState.Error;
		case DeviceStatus.Offline:
			return DeviceState.Offline;
		case DeviceStatus.Online:
			return DeviceState.Online;
		default:
			return DeviceState.Error;
	}
}

export function mapUptimeDurationFromSysApi(duration: string | undefined): string {
	if (!duration || duration === MISSING_VALUE) return '';

	// Converts ISO 8601 time duration to [h]:mm:ss
	return Duration.fromISO(duration).toFormat('hh:mm:ss');
}
