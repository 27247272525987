import { ModuleWithProviders, NgModule, Type } from '@angular/core';

import { DEVICE_BEHAVIOR_PLUGIN_TOKEN } from './device-behavior.service';
import { DeviceBehaviorPlugin } from './models';

@NgModule()
export class DeviceBehaviorPluginsModule {
	/**
	 * Registers passed device behavior plugins as globally available plugins.
	 */
	public static forRoot(plugins: Type<DeviceBehaviorPlugin>[]): ModuleWithProviders<DeviceBehaviorPluginsModule> {
		return {
			ngModule: DeviceBehaviorPluginsModule,
			providers: [
				...plugins,
				...plugins.map((p) => ({
					provide: DEVICE_BEHAVIOR_PLUGIN_TOKEN,
					useExisting: p,
					multi: true
				}))
			]
		};
	}
}
