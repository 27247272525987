/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Assign } from '../models/assign';
import { AssignCreate } from '../models/assign-create';
import { AssignUpdate } from '../models/assign-update';
import { LicenseGroupDetailsResponse } from '../models/license-group-details-response';
import { LicenseGroupDto } from '../models/license-group-dto';
import { LicenseGroupsResponse } from '../models/license-groups-response';
import { LicenseTypesListResponse } from '../models/license-types-list-response';
import { LicensesCountResponse } from '../models/licenses-count-response';
import { MessagesResponse } from '../models/messages-response';
import { CreateAssignmentResponse } from '../models/create-assignment-response';

@Injectable({
  providedIn: 'root',
})
export class CloudLicenseGroupApiService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation getLicenseGroupsCount
   */
  static readonly GetLicenseGroupsCountPath = '/api/v1/groups/counts';

  /**
   * To fetch the count of license groups, 16 channel and 8 channel licenses.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLicenseGroupsCount()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLicenseGroupsCount$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<LicensesCountResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CloudLicenseGroupApiService.GetLicenseGroupsCountPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LicensesCountResponse>;
      })
    );
  }

  /**
   * To fetch the count of license groups, 16 channel and 8 channel licenses.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getLicenseGroupsCount$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLicenseGroupsCount(params?: {
    context?: HttpContext
  }
): Observable<LicensesCountResponse> {

    return this.getLicenseGroupsCount$Response(params).pipe(
      map((r: StrictHttpResponse<LicensesCountResponse>) => r.body as LicensesCountResponse)
    );
  }

  /**
   * Path part for operation getLicenseGroupsList
   */
  static readonly GetLicenseGroupsListPath = '/api/v1/groups';

  /**
   * To fetch the list of license groups and their details.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLicenseGroupsList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLicenseGroupsList$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<LicenseGroupsResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CloudLicenseGroupApiService.GetLicenseGroupsListPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LicenseGroupsResponse>;
      })
    );
  }

  /**
   * To fetch the list of license groups and their details.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getLicenseGroupsList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLicenseGroupsList(params?: {
    context?: HttpContext
  }
): Observable<LicenseGroupsResponse> {

    return this.getLicenseGroupsList$Response(params).pipe(
      map((r: StrictHttpResponse<LicenseGroupsResponse>) => r.body as LicenseGroupsResponse)
    );
  }

  /**
   * Path part for operation getLicenseGroupsListByLicenseType
   */
  static readonly GetLicenseGroupsListByLicenseTypePath = '/api/v2/{licenseType}/groups';

  /**
   * To fetch the list of license groups and their details by licenseType.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLicenseGroupsListByLicenseType()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLicenseGroupsListByLicenseType$Response(params: {
    licenseType: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<LicenseGroupsResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CloudLicenseGroupApiService.GetLicenseGroupsListByLicenseTypePath, 'get');
    if (params) {
      rb.path('licenseType', params.licenseType, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LicenseGroupsResponse>;
      })
    );
  }

  /**
   * To fetch the list of license groups and their details by licenseType.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getLicenseGroupsListByLicenseType$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLicenseGroupsListByLicenseType(params: {
    licenseType: string;
    context?: HttpContext
  }
): Observable<LicenseGroupsResponse> {

    return this.getLicenseGroupsListByLicenseType$Response(params).pipe(
      map((r: StrictHttpResponse<LicenseGroupsResponse>) => r.body as LicenseGroupsResponse)
    );
  }

  /**
   * Path part for operation getLicenseProductTypes
   */
  static readonly GetLicenseProductTypesPath = '/api/v1/product-types';

  /**
   * To fetch list of Licensed product types.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLicenseProductTypes()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLicenseProductTypes$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<LicenseTypesListResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CloudLicenseGroupApiService.GetLicenseProductTypesPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LicenseTypesListResponse>;
      })
    );
  }

  /**
   * To fetch list of Licensed product types.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getLicenseProductTypes$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLicenseProductTypes(params?: {
    context?: HttpContext
  }
): Observable<LicenseTypesListResponse> {

    return this.getLicenseProductTypes$Response(params).pipe(
      map((r: StrictHttpResponse<LicenseTypesListResponse>) => r.body as LicenseTypesListResponse)
    );
  }

  /**
   * Path part for operation createLicenseAssignment
   */
  static readonly CreateLicenseAssignmentPath = '/api/v1/assign';

  /**
   * Create a license assignment
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createLicenseAssignment()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createLicenseAssignment$Response(params: {
    context?: HttpContext

    /**
     * Create a license assignment
     */
    body: AssignCreate
  }
): Observable<StrictHttpResponse<CreateAssignmentResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CloudLicenseGroupApiService.CreateLicenseAssignmentPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CreateAssignmentResponse>;
      })
    );
  }

  /**
   * Create a license assignment
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `createLicenseAssignment$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createLicenseAssignment(params: {
    context?: HttpContext

    /**
     * Create a license assignment
     */
    body: AssignCreate
  }
): Observable<CreateAssignmentResponse> {

    return this.createLicenseAssignment$Response(params).pipe(
      map((r: StrictHttpResponse<CreateAssignmentResponse>) => r.body as CreateAssignmentResponse)
    );
  }

  /**
   * Path part for operation updateLicenseAssignment
   */
  static readonly UpdateLicenseAssignmentPath = '/api/v1/assign';

  /**
   * Update a license assignment
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateLicenseAssignment()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateLicenseAssignment$Response(params: {
    context?: HttpContext

    /**
     * Update a license assignment
     */
    body: AssignUpdate
  }
): Observable<StrictHttpResponse<Assign>> {

    const rb = new RequestBuilder(this.rootUrl, CloudLicenseGroupApiService.UpdateLicenseAssignmentPath, 'patch');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Assign>;
      })
    );
  }

  /**
   * Update a license assignment
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateLicenseAssignment$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateLicenseAssignment(params: {
    context?: HttpContext

    /**
     * Update a license assignment
     */
    body: AssignUpdate
  }
): Observable<Assign> {

    return this.updateLicenseAssignment$Response(params).pipe(
      map((r: StrictHttpResponse<Assign>) => r.body as Assign)
    );
  }

  /**
   * Path part for operation getLicenseGroupsDetails
   */
  static readonly GetLicenseGroupsDetailsPath = '/api/v1/groups/{licenseId}/details';

  /**
   * To fetch details of license groups and the active endpoints.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getLicenseGroupsDetails()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLicenseGroupsDetails$Response(params: {
    licenseId: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<LicenseGroupDetailsResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CloudLicenseGroupApiService.GetLicenseGroupsDetailsPath, 'get');
    if (params) {
      rb.path('licenseId', params.licenseId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<LicenseGroupDetailsResponse>;
      })
    );
  }

  /**
   * To fetch details of license groups and the active endpoints.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `getLicenseGroupsDetails$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getLicenseGroupsDetails(params: {
    licenseId: string;
    context?: HttpContext
  }
): Observable<LicenseGroupDetailsResponse> {

    return this.getLicenseGroupsDetails$Response(params).pipe(
      map((r: StrictHttpResponse<LicenseGroupDetailsResponse>) => r.body as LicenseGroupDetailsResponse)
    );
  }

  /**
   * Path part for operation decommissionLicenseEndpoint
   */
  static readonly DecommissionLicenseEndpointPath = '/api/v1/groups/{licenseId}/devices/{deviceId}';

  /**
   * To decommission an license group endpoint.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `decommissionLicenseEndpoint()` instead.
   *
   * This method doesn't expect any request body.
   */
  decommissionLicenseEndpoint$Response(params: {
    licenseId: string;
    deviceId: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<MessagesResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CloudLicenseGroupApiService.DecommissionLicenseEndpointPath, 'delete');
    if (params) {
      rb.path('licenseId', params.licenseId, {});
      rb.path('deviceId', params.deviceId, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MessagesResponse>;
      })
    );
  }

  /**
   * To decommission an license group endpoint.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `decommissionLicenseEndpoint$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  decommissionLicenseEndpoint(params: {
    licenseId: string;
    deviceId: string;
    context?: HttpContext
  }
): Observable<MessagesResponse> {

    return this.decommissionLicenseEndpoint$Response(params).pipe(
      map((r: StrictHttpResponse<MessagesResponse>) => r.body as MessagesResponse)
    );
  }

  /**
   * Path part for operation updateLicenseGroupsNickname
   */
  static readonly UpdateLicenseGroupsNicknamePath = '/api/v1/groups/{licenseId}';

  /**
   * To update license groups nickname.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateLicenseGroupsNickname()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateLicenseGroupsNickname$Response(params: {
    licenseId: string;
    context?: HttpContext
    body: LicenseGroupDto
  }
): Observable<StrictHttpResponse<MessagesResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CloudLicenseGroupApiService.UpdateLicenseGroupsNicknamePath, 'post');
    if (params) {
      rb.path('licenseId', params.licenseId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MessagesResponse>;
      })
    );
  }

  /**
   * To update license groups nickname.
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `updateLicenseGroupsNickname$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateLicenseGroupsNickname(params: {
    licenseId: string;
    context?: HttpContext
    body: LicenseGroupDto
  }
): Observable<MessagesResponse> {

    return this.updateLicenseGroupsNickname$Response(params).pipe(
      map((r: StrictHttpResponse<MessagesResponse>) => r.body as MessagesResponse)
    );
  }

  /**
   * Path part for operation moveLicensesFromOneGroupToAnother
   */
  static readonly MoveLicensesFromOneGroupToAnotherPath = '/api/v1/{licenseType}/groups/licenses';

  /**
   * To move licenses from the given license group to a new license group or an existing license group
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `moveLicensesFromOneGroupToAnother()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  moveLicensesFromOneGroupToAnother$Response(params: {
    licenseType: string;
    context?: HttpContext
    body: {
'fromLicenseGroupId': string;
'count': number;
'newLicenseGroupName'?: string | null;
'toLicenseGroupId'?: string | null;
}
  }
): Observable<StrictHttpResponse<MessagesResponse>> {

    const rb = new RequestBuilder(this.rootUrl, CloudLicenseGroupApiService.MoveLicensesFromOneGroupToAnotherPath, 'patch');
    if (params) {
      rb.path('licenseType', params.licenseType, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'application/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MessagesResponse>;
      })
    );
  }

  /**
   * To move licenses from the given license group to a new license group or an existing license group
   *
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `moveLicensesFromOneGroupToAnother$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  moveLicensesFromOneGroupToAnother(params: {
    licenseType: string;
    context?: HttpContext
    body: {
'fromLicenseGroupId': string;
'count': number;
'newLicenseGroupName'?: string | null;
'toLicenseGroupId'?: string | null;
}
  }
): Observable<MessagesResponse> {

    return this.moveLicensesFromOneGroupToAnother$Response(params).pipe(
      map((r: StrictHttpResponse<MessagesResponse>) => r.body as MessagesResponse)
    );
  }

}
